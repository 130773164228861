import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { BsFillLightningFill } from 'react-icons/bs';

import { IBancasDTO, IProdutosDTO, ISelectDTO } from 'utils/DTOS';
import getValidationErrors from 'utils/getValidationErrors';
import api from 'services/api';
import { useToast } from 'hooks/toast';

import Input from 'components/Input';
import Select from 'components/Select';

import { Line, LineItem, List } from 'styles/others';

import CreatePdfEncalheJs from 'components/CreatePdfEncalheJs';
import {
  Container,
  TitleHolder,
  ContentHolder,
  FormRow,
  InputHolder,
  Sidebar,
  DadosEncalhe,
  DadosProdutos,
  Main,
  InnerTitle,
} from './styles';

interface ParamsTypes {
  id?: string;
}

export interface IDadosEncalhe {
  vencimento: Date;
  banca_id: string;
}

export interface IProdutosEncalhe {
  key: number;
  qtd: number;
  nota: string;
  produto_id: string;
  produto_nome: string;
}

interface ISubmitData {
  dadosEncalhe: IDadosEncalhe;
  produtosEncalhe: IProdutosEncalhe[];
}

const Encalhe: React.FC = () => {
  const { addToast } = useToast();
  const grid = '1fr 100px 80px 100px 50px';

  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [idInicial, setIdInicial] = useState<number | undefined>(undefined);
  const [produtosEncalhe, setProdutosEncalhe] = useState<IProdutosEncalhe[]>(
    []
  );
  const [produtos, setProdutos] = useState<ISelectDTO[]>([]);
  const [bancas, setBancas] = useState<ISelectDTO[]>([]);
  const [encalheData, setEncalheData] = useState<ISubmitData | undefined>(
    undefined
  );

  const dadosEncalheRef = useRef<FormHandles>(null);
  const dadosProdutosRef = useRef<FormHandles>(null);

  // Se tiver id de parametro habilita edição
  const { id } = useParams<ParamsTypes>();

  useEffect(() => {
    if (id) {
      setEditing(true);
    }
  }, [id]);

  // Produtos para select
  const getIdInicial = useCallback(async () => {
    await api
      .get('/encalhes/last-id')
      .then(async (res: AxiosResponse) => {
        setIdInicial(parseInt(res.data.id.toString()) + 1);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar o id inicial, recarregue a página.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    if (!editing) {
      getIdInicial();
    }
  }, [getIdInicial, editing]);

  // Produtos para select
  const getProdutos = useCallback(async () => {
    await api
      .get('/produtos')
      .then(async (res: AxiosResponse) => {
        const tmp_produtos: IProdutosDTO[] = res.data;
        const final_produtos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_produtos.length; i++) {
          const tmp_produto = tmp_produtos[i];

          final_produtos.push({
            label: `${tmp_produto.cod_produto} - ${tmp_produto.des_produto}${
              tmp_produto.edicao ? ` - ${tmp_produto.edicao}` : ''
            }`,
            value: tmp_produto.id.toString(),
          });
        }

        setProdutos(final_produtos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os produtos, recarregue a página.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getProdutos();
  }, [getProdutos]);

  // Bancas para select
  const getBancas = useCallback(async () => {
    await api
      .get('/bancas')
      .then(async (res: AxiosResponse) => {
        const tmp_bancas: IBancasDTO[] = res.data;
        const final_bancas: ISelectDTO[] = [];

        for (let i = 0; i < tmp_bancas.length; i++) {
          const tmp_banca = tmp_bancas[i];

          final_bancas.push({
            label: `#${tmp_banca.cod_ponto}-${tmp_banca.nome}`,
            value: tmp_banca.id.toString(),
          });
        }

        setBancas(final_bancas);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar as bancas, recarregue a página.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getBancas();
  }, [getBancas]);

  // Setar dados de encalhe
  const getDadosEncalhe = useCallback(async (): Promise<IDadosEncalhe> => {
    setLoading(true);
    try {
      const data: any = dadosEncalheRef.current?.getData();

      const schema = Yup.object().shape({
        banca_id: Yup.string().required('Banca obrigatória'),
        vencimento: Yup.date().required('Vencimento obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      return data;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        dadosEncalheRef.current?.setErrors(errors);
      }

      addToast({
        type: 'error',
        title: err.message || `Erro na adição do produto`,
        description: `Ocorreu um erro ao adicionar o produto, cheque as informações e tente novamente.`,
      });
      console.error(err);
      throw new Error('Erro ao solicitar os dados de encalhe');
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  // Setar dados de encalhe
  const addProdutosEncalhe = useCallback(
    async (data: IProdutosEncalhe) => {
      setLoading(true);
      try {
        const schema = Yup.object().shape({
          produto_id: Yup.string().required('Produto obrigatório'),
          qtd: Yup.string().required('Quantidade obrigatória'),
          nota: Yup.string().required('Nota obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.key = Math.floor(Math.random() * 10);

        data.produto_nome =
          produtos.find(produto => produto.value === data.produto_id)?.label ||
          '-';

        setProdutosEncalhe([...produtosEncalhe, data]);

        dadosProdutosRef.current?.clearField('produto_id');
        dadosProdutosRef.current?.clearField('qtd');
        dadosProdutosRef.current?.clearField('nota');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          dadosProdutosRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na adição do produto`,
          description: `Ocorreu um erro ao adicionar o produto, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [produtosEncalhe, produtos, addToast]
  );

  // Setar dados de encalhe
  const removeProdutosEncalhe = useCallback(
    (produto_key: string) => {
      const produtosFiltered = produtosEncalhe.filter(
        produto =>
          `${produto.key}-${produto.produto_id}-${produto.nota}-${produto.qtd}` !==
          produto_key
      );
      setProdutosEncalhe([...produtosFiltered]);
    },
    [produtosEncalhe]
  );

  // Enviar encalhe
  const handleSubmit = useCallback(
    async (dat_id: string | number) => {
      try {
        setLoading(true);

        const dadosEncalhe = await getDadosEncalhe();

        if (!dadosEncalhe) {
          addToast({
            type: 'error',
            title: `Dados do encalhe faltantes.`,
          });
          return;
        }

        if (produtosEncalhe.length === 0) {
          addToast({
            type: 'error',
            title: `Nenhum produto adicionado.`,
          });
          return;
        }

        const data: ISubmitData = {
          dadosEncalhe,
          produtosEncalhe,
        };

        api
          .post(`/encalhes/full`, data)
          .then((res: AxiosResponse) => {
            addToast({
              type: 'success',
              title: `Encalhe #${dat_id} criado com sucesso - PDF pronto para ser gerado`,
            });

            setEncalheData(data);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao criar o encalhe, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: err.message || `Erro na criação do encalhe`,
          description: `Ocorreu um erro ao criar o encalhe, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, produtosEncalhe, getDadosEncalhe]
  );

  return (
    <Container>
      <TitleHolder>
        {editing ? '#1' : 'Novo'} - Encalhe:{' '}
        <div className="buttons">
          {/* <CreatePdfEncalheJs /> */}
          <button
            type="button"
            onClick={() => idInicial && handleSubmit(idInicial)}
          >
            Salvar
          </button>
        </div>
      </TitleHolder>
      <ContentHolder>
        <Sidebar borderColor="primary">
          <InnerTitle>
            Dados do encalhe{idInicial !== null ? ` #${idInicial}:` : ':'}
          </InnerTitle>
          <DadosEncalhe onSubmit={getDadosEncalhe} ref={dadosEncalheRef}>
            <FormRow>
              <InputHolder>
                <label htmlFor="banca_id">Banca*:</label>
                <Select options={bancas} name="banca_id" />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="vencimento">Vencimento*:</label>
                <Input name="vencimento" type="date" placeholder="Vencimento" />
              </InputHolder>
            </FormRow>
          </DadosEncalhe>
          <InnerTitle>
            Produtos:{' '}
            <button
              type="button"
              onClick={() => !loading && dadosProdutosRef.current?.submitForm()}
            >
              <FaPlus />
            </button>
          </InnerTitle>
          <DadosProdutos onSubmit={addProdutosEncalhe} ref={dadosProdutosRef}>
            <FormRow>
              <InputHolder>
                <label htmlFor="produto_id">Produto*:</label>
                <Select options={produtos} name="produto_id" />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="qtd">Quantidade*:</label>
                <Input name="qtd" type="number" placeholder="Quantidade" />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="nota">Nota*:</label>
                <Input name="nota" placeholder="Nota" />
              </InputHolder>
            </FormRow>
          </DadosProdutos>
        </Sidebar>
        <Main borderColor="primary">
          <>
            <InnerTitle>Produtos adicionados:</InnerTitle>
            <List>
              <Line
                className="indice"
                style={{
                  gridTemplateColumns: grid,
                  borderColor: '#ddd',
                }}
              >
                <LineItem>Produto</LineItem>
                <LineItem>Nota</LineItem>
                <LineItem>Qtd</LineItem>
                <LineItem>Dev</LineItem>
                <LineItem>
                  <BsFillLightningFill size={15} />
                </LineItem>
              </Line>
              {produtosEncalhe.map(produto => {
                const key = `${produto.key}-${produto.produto_id}-${produto.nota}-${produto.qtd}`;
                return (
                  <Line
                    key={key}
                    style={{
                      gridTemplateColumns: grid,
                      borderColor: '#ddd',
                    }}
                  >
                    <LineItem>{produto.produto_nome}</LineItem>
                    <LineItem>{produto.nota}</LineItem>
                    <LineItem>{produto.qtd}</LineItem>
                    <LineItem />
                    <LineItem>
                      <FaTrash
                        size={15}
                        style={{ cursor: 'pointer' }}
                        onClick={() => !loading && removeProdutosEncalhe(key)}
                      />
                    </LineItem>
                  </Line>
                );
              })}
            </List>
          </>
        </Main>
      </ContentHolder>
    </Container>
  );
};

export default Encalhe;

import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import AuthLayout from '../pages/_layouts/auth';
import AdminLayout from '../pages/_layouts/admin';
import DefaultLayout from '../pages/_layouts/default';

import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
  component: React.ComponentType;
}
const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isAdmin = false,
  component: Component,
  ...rest
}) => {
  const auth = useAuth();
  const { usuario } = auth;
  let admin = false;

  if (usuario) {
    const { permissao } = usuario;
    admin = permissao === 10;
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if ((!usuario && isPrivate) || (!usuario && isAdmin)) {
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          );
        }

        if (!!usuario && !isPrivate && !isAdmin) {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }

        if (!!usuario && !admin && isAdmin) {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }

        let Layout = DefaultLayout;

        if (admin) {
          Layout = AdminLayout;
        } else {
          Layout = usuario ? AuthLayout : DefaultLayout;
        }

        return (
          <Layout>
            <Component />
          </Layout>
        );
      }}
    />
  );
};

export default Route;

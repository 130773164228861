import React from 'react';
import { useHistory } from 'react-router';

import BoxContainer from 'components/BoxContainer';
import Listagem, { IRow } from 'components/Listagem';

import { Container, TitleHolder, ListHolder } from './styles';

const Encalhes: React.FC = () => {
  const history = useHistory();

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      label: 'Vencimento',
      data: 'vencimento',
    },
    {
      label: 'Banca',
      data: '__banca__.nome',
    },
    {
      label: 'Qtd Produtos',
      data: 'qtdProdutos',
    },
  ];

  return (
    <Container>
      <TitleHolder>
        Encalhes:
        <button type="button" onClick={() => history.push('/encalhe')}>
          Novo encalhe
        </button>
      </TitleHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <Listagem
            rows={rows}
            module="encalhes"
            template="100px 140px 2.5fr 150px"
            getUrl="/encalhes/list"
            editUrl="/encalhe"
            indexUrl="/encalhe"
            deleteUrl="/encalhes/encalhe"
          />
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default Encalhes;

import React, { SetStateAction, useCallback, useEffect, useState } from 'react';

import { FiMenu } from 'react-icons/fi';
import { FaCheck, FaClock, FaSignOutAlt } from 'react-icons/fa';

import Logo from 'assets/cropped-logo.png';
import UserDefault from 'assets/userDefault.png';

import { format } from 'date-fns';
import { useHistory } from 'react-router';
import { IJWTDTO, IUsuariosDTO } from 'utils/DTOS';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { AxiosError, AxiosResponse } from 'axios';
import Spinner from 'components/Spinner';
import { IoMdClose } from 'react-icons/io';
import { useAuth } from '../../../hooks/auth';
import {
  HeaderHolder,
  Header1Holder,
  Separator,
  MenuHolder,
  LogoHolder,
  UserHolder,
  DateHolder,
  SignOutHolder,
  Header2Holder,
  TasksHolder,
  WarningTaskInfo,
} from './styled';

interface IProps {
  isSidebarOpened: boolean;
  setSidebarOpened: React.Dispatch<SetStateAction<boolean>>;
}

const HeaderLogged: React.FC<IProps> = props => {
  const { isSidebarOpened, setSidebarOpened } = props;

  const history = useHistory();
  const { signOut } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [usuario, setUsuario] = useState<IUsuariosDTO | undefined>(undefined);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const { addToast } = useToast();

  const getUsuarios = useCallback(async () => {
    setLoading(true);

    const userJSON = localStorage.getItem('@FGLivraria-Sys:usuario');

    if (usuario) {
      setLoading(false);
      return;
    }
    if (!userJSON) {
      addToast({
        type: 'error',
        title: 'Dados não encontrados',
        description: 'Entre novamente no sistema para usa-lo.',
      });
      return;
    }
    const user: IJWTDTO = JSON.parse(userJSON);
    const { id } = user;

    await api
      .get(`/usuarios/usuario/${id}`)
      .then(async (res: AxiosResponse) => {
        setUsuario(res.data);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          signOut();

          addToast({
            type: 'error',
            title:
              typeof err.response?.data.message === 'string'
                ? err.response?.data.message
                : 'Ocorreu um erro',
            description: 'Problema na autênticação, logue novamente.',
          });
        }
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os seus dados, entre novamente no sistema.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, usuario, signOut]);

  useEffect(() => {
    getUsuarios();
  }, [getUsuarios]);

  const getFullDate = useCallback(() => {
    const tempDate = new Date();

    setDate(tempDate);
  }, []);

  process.env.NODE_ENV !== 'development' && setTimeout(getFullDate, 1000);

  return (
    <HeaderHolder isSidebarOpened={isSidebarOpened}>
      <Header1Holder>
        <MenuHolder>
          {isSidebarOpened ? (
            <IoMdClose
              size={18}
              onClick={() => {
                setSidebarOpened(!isSidebarOpened);
              }}
            />
          ) : (
            <FiMenu
              size={18}
              onClick={() => {
                setSidebarOpened(!isSidebarOpened);
              }}
            />
          )}
        </MenuHolder>
        <Separator />
        <LogoHolder>
          <img src={Logo} alt="Logo" />
        </LogoHolder>
        <UserHolder>
          <span>
            {loading ? (
              <strong>
                <Spinner size={15} />
              </strong>
            ) : (
              usuario && (
                <>
                  Olá, <strong>{usuario.nome}</strong>
                </>
              )
            )}
          </span>
          <img src={UserDefault} alt="Avatar" />
        </UserHolder>
        <Separator />
        <DateHolder>
          <FaClock size={18} />
          <span>{date && format(new Date(date), 'dd/MM/yyyy')}</span>
          <span>-</span>
          <span>{date && format(new Date(date), 'HH:mm')}</span>
        </DateHolder>
        <Separator />
        <SignOutHolder>
          <button
            type="button"
            onClick={() => {
              signOut();
            }}
          >
            <FaSignOutAlt size={18} />
          </button>
        </SignOutHolder>
      </Header1Holder>
    </HeaderHolder>
  );
};

export default HeaderLogged;

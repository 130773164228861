import { Form } from '@unform/web';
import BoxContainer from 'components/BoxContainer';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 2%;
  }
`;

export const TitleHolder = styled.h2`
  width: 100%;
  padding: 0 15px 10px;

  border-bottom: 1px solid rgb(175 175 175 / 30%);

  color: var(--text-color-2);
  font-size: 2.4rem;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      height: 35px;
      padding: 0 30px;
      font-weight: 500;
      font-size: 1.4rem;
      text-decoration: none;
      color: var(--text-color-1);
      border-radius: 30px;
      transition: 0.3s all;
      text-shadow: 0 0 10px #000;
      box-shadow: 0 0 4px var(--shadow-color);
      background-color: var(--success-color);

      margin-right: 10px;

      &.pdf {
        padding: 0;
        width: 35px;
        background-color: var(--error-color);
        &:hover {
          padding: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        padding: 8px 20px;
        box-shadow: 0 0 8px var(--shadow-color);
      }
    }
  }
`;

export const ContentHolder = styled.div`
  width: 100%;

  flex: 1 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FormRow = styled.div`
  width: 100%;
  height: 100%;

  margin: 0 0 10px;
  padding: 0 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 0;
  }
`;

export const InputHolder = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  margin: 0 10px;

  &:first-of-type {
    margin-left: 0px;
  }
  &:last-of-type {
    margin-right: 0px;
  }

  .checkboxHolder {
    height: 44px;
    justify-content: center;
    align-items: flex-start;
    span {
      font-size: 1.4rem;
    }
    input[type='checkbox'] {
      transform: scale(1.2);
      margin-right: 10px;
    }
  }

  label {
    font-size: 1.15rem;
    margin-bottom: 3px;
  }

  @media (max-width: 768px) {
    width: 100% !important;
    margin: 10px 0;
  }
`;

export const Sidebar = styled(BoxContainer)`
  width: 35% !important;
  max-height: 555px;
  flex: auto !important;

  overflow-y: scroll !important;
  overflow-x: visible !important;

  margin-right: 30px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const InnerTitle = styled.h3`
  width: 100%;
  padding: 15px 10px 10px;

  border-bottom: 1px solid var(--primary-shadow);

  color: var(--text-color-2);
  font-size: 1.5rem;
  font-weight: 300;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-of-type {
    padding-top: 0px;
  }

  button {
    width: 30px;
    height: 30px;
    color: var(--text-color-1);
    border-radius: 50%;
    transition: 0.3s all;
    box-shadow: 0 0 4px var(--shadow-color);
    background-color: var(--success-color);

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: 0 0 8px var(--shadow-color);
    }
  }
`;

export const DadosEncalhe = styled(Form)`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Main = styled(BoxContainer)`
  width: calc(65% - 30px) !important;
  flex: auto !important;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const DadosProdutos = styled(Form)`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

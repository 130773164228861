import React from 'react';
import { useHistory } from 'react-router';

import BoxContainer from 'components/BoxContainer';
import Listagem, { IRow } from 'components/Listagem';

import { Container, TitleHolder, ListHolder } from '../styles';

const Bancas: React.FC = () => {
  const history = useHistory();

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      data: 'cod_ponto',
      label: 'Código Ponto',
    },
    {
      data: 'nome',
      label: 'Nome',
    },
    {
      data: 'razao_social',
      label: 'Razão Social',
    },
    {
      data: 'tipo',
      label: 'Tipo',
    },
    {
      data: 'contato',
      label: 'Contato',
    },
    {
      data: 'endereco',
      label: 'Endereço',
    },
  ];

  return (
    <Container>
      <TitleHolder>
        Bancas:
        <button
          type="button"
          onClick={() => history.push('/cadastros/bancas/novo')}
        >
          Nova banca
        </button>
      </TitleHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <Listagem
            rows={rows}
            module="bancas"
            template="100px 200px 1fr 1fr 1fr 1fr 1fr"
            editUrl="/cadastros/bancas/banca"
            indexUrl="/cadastros/bancas/banca"
            deleteUrl="/bancas/banca"
          />
          {/* <PaginationHolder>
            div.
          </PaginationHolder> */}
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default Bancas;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { AiOutlineHome } from 'react-icons/ai';
import { FaChartBar, FaTruck } from 'react-icons/fa';

import { SidebarHolder, Nav, NavItem } from './styled';

interface IProps {
  isOpened: boolean;
}

const Sidebar: React.FC<IProps> = props => {
  const history = useHistory();

  const { isOpened } = props;

  return (
    <SidebarHolder open={isOpened}>
      <Nav>
        <NavItem
          onClick={() => {
            history.push('/');
          }}
          type="button"
          id="inicio"
        >
          <AiOutlineHome size={20} />
          <span>Início</span>
        </NavItem>
        <NavItem
          onClick={() => {
            history.push('/encalhes');
          }}
          type="button"
          id="encalhes"
        >
          <FaTruck size={20} />
          <span>Encalhes</span>
        </NavItem>
      </Nav>
    </SidebarHolder>
  );
};

export default Sidebar;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import getValidationErrors from 'utils/getValidationErrors';

import { Container, FormContainer, Title, Desc } from '../novo-styles';

const NovoProduto: React.FC = () => {
  const module = 'produto';
  const module_label = 'Produto';
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const inputs: IInput[] = [
    {
      name: 'cod_produto',
      label: 'Código Produto',
      tipo: 'input',
      type: 'number',
      required: true,
    },
    {
      name: 'des_produto',
      label: 'Descrição Produto',
      tipo: 'input',
      required: true,
    },
    {
      name: 'edicao',
      label: 'Edição',
      tipo: 'input',
    },
    {
      name: 'isbn',
      label: 'ISBN',
      tipo: 'input',
      required: true,
    },
    {
      name: 'valor_un',
      label: 'Valor Unitário',
      tipo: 'currency',
      required: true,
    },
    {
      name: 'desconto',
      label: 'Desconto',
      tipo: 'input',
      type: 'number',
    },
  ];

  const history = useHistory();

  const { addToast } = useToast();

  const tratarValor = useCallback((valor: string) => {
    const valorString = valor
      .replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.');

    const split = valorString.split('.');

    if (
      split.length > 1 &&
      split[split.length - 1] &&
      split[split.length - 1].length === 3
    ) {
      split[split.length - 1] = split[split.length - 1].substring(
        0,
        split[split.length - 1].length - 1
      );
    }

    const valorFinal = parseFloat(`${split[0]}.${split[1]}`);

    return valorFinal;
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        data.valor_un = tratarValor(data.valor_un);

        const schema = Yup.object().shape({
          cod_produto: Yup.string().required('Código do produto obrigatório'),
          des_produto: Yup.string().required(
            'Descrição do produto obrigatório'
          ),
          isbn: Yup.string().required('ISBN obrigatório'),
          valor_un: Yup.string().required('Valor unitário obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post(`/${module}s`, data)
          .then((res: AxiosResponse) => {
            const { id } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                id && ` de id: ${id}`
              } criado com sucesso`,
            });
            history.push(`/cadastros/${module}s`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na criação do ${module}`,
          description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, history]
  );

  return (
    <Container>
      <Title>Novo {module_label}:</Title>
      <Desc>
        Campos com <b>* (asterisco)</b> são obrigatórios
      </Desc>
      <FormContainer>
        <FormConstructor
          color="quaternary"
          formColumns="1fr"
          formRef={formRef}
          inputs={inputs}
          saveBtn={saveBtn}
          submitFnc={handleSubmit}
        />
      </FormContainer>
    </Container>
  );
};

export default NovoProduto;

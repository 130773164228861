import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import getValidationErrors from 'utils/getValidationErrors';

import { Container, FormContainer, Title, Desc } from '../novo-styles';

const NovaBanca: React.FC = () => {
  const module = 'banca';
  const module_label = 'Banca';
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const inputs: IInput[] = [
    {
      name: 'cod_ponto',
      label: 'Código Ponto',
      tipo: 'input',
      type: 'number',
      required: true,
    },
    {
      name: 'nome',
      label: 'Nome',
      tipo: 'input',
      required: true,
    },
    {
      name: 'razao_social',
      label: 'Razão Social',
      tipo: 'input',
      required: true,
    },
    {
      name: 'tipo',
      label: 'Tipo',
      tipo: 'input',
      required: true,
    },
    {
      name: 'contato',
      label: 'Contato',
      tipo: 'input',
    },
    {
      name: 'endereco',
      label: 'Endereço',
      tipo: 'input',
      required: true,
    },
    {
      name: 'numero',
      label: 'Número',
      tipo: 'input',
      required: true,
    },
    {
      name: 'bairro',
      label: 'Bairro',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cidade',
      label: 'Cidade',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cep',
      label: 'CEP',
      tipo: 'input',
      required: true,
    },
    {
      name: 'telefone',
      label: 'Telefone',
      tipo: 'phone',
      required: true,
    },
    {
      name: 'cnpj',
      label: 'CNPJ',
      tipo: 'input',
      required: true,
    },
    {
      name: 'insc_estadual',
      label: 'Inscrição Estadual',
      tipo: 'input',
      required: true,
    },
    {
      name: 'email',
      label: 'E-mail',
      tipo: 'input',
      type: 'email',
    },
  ];

  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cod_ponto: Yup.number().required('Código do Ponto obrigatório'),
          nome: Yup.string().required('Nome obrigatório'),
          razao_social: Yup.string().required('Razão social obrigatória'),
          tipo: Yup.string().required('Tipo obrigatório'),
          endereco: Yup.string().required('Endereço obrigatório'),
          numero: Yup.string().required('Número obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          cep: Yup.string().required('CEP obrigatório'),
          telefone: Yup.string().required('Telefone obrigatório'),
          cnpj: Yup.string().required('CNPJ obrigatório'),
          insc_estadual: Yup.string().required(
            'Inscrição Estadual obrigatório'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post(`/${module}s`, data)
          .then((res: AxiosResponse) => {
            const { id } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                id && ` de id: ${id}`
              } criado com sucesso`,
            });
            history.push(`/cadastros/${module}s`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na criação do ${module}`,
          description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, history]
  );

  return (
    <Container>
      <Title>Novo {module_label}:</Title>
      <Desc>
        Campos com <b>* (asterisco)</b> são obrigatórios
      </Desc>
      <FormContainer>
        <FormConstructor
          color="quaternary"
          formColumns="1fr"
          formRef={formRef}
          inputs={inputs}
          saveBtn={saveBtn}
          submitFnc={handleSubmit}
        />
      </FormContainer>
    </Container>
  );
};

export default NovaBanca;

import React from 'react';
import { Switch } from 'react-router-dom';

import Inicio from 'pages/Inicio';

import Login from 'pages/Login';

import Pag404 from 'pages/404';

import Encalhes from 'pages/Encalhes';
import Encalhe from 'pages/Encalhe';

import Cadastros from 'pages/Cadastros';

import Usuarios from 'pages/Cadastros/Internas/Usuarios';
import NovoUsuario from 'pages/Cadastros/Internas/Usuarios/novo';
import UsuariosInterna from 'pages/Cadastros/Internas/Usuarios/interna';

import Bancas from 'pages/Cadastros/Internas/Bancas';
import NovaBanca from 'pages/Cadastros/Internas/Bancas/novo';
import BancasInterna from 'pages/Cadastros/Internas/Bancas/interna';

import Produtos from 'pages/Cadastros/Internas/Produtos';
import NovoProduto from 'pages/Cadastros/Internas/Produtos/novo';
import ProdutosInterna from 'pages/Cadastros/Internas/Produtos/interna';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" exact component={Login} />

    <Route path="/" exact component={Inicio} isPrivate />

    <Route path="/encalhes" exact component={Encalhes} isPrivate />

    <Route path="/encalhe" exact component={Encalhe} isPrivate />
    <Route path="/encalhe/:id" exact component={Encalhe} isPrivate />

    {/* Cadastros */}
    <Route path="/cadastros" exact component={Cadastros} isAdmin />
    {/* ------ */}
    <Route path="/cadastros/usuarios" exact component={Usuarios} isAdmin />
    <Route
      path="/cadastros/usuarios/novo"
      exact
      component={NovoUsuario}
      isAdmin
    />
    <Route
      path="/cadastros/usuarios/usuario/:id"
      exact
      component={UsuariosInterna}
      isAdmin
    />
    {/* ------ */}
    <Route path="/cadastros/bancas" exact component={Bancas} isAdmin />
    <Route path="/cadastros/bancas/novo" exact component={NovaBanca} isAdmin />
    <Route
      path="/cadastros/bancas/banca/:id"
      exact
      component={BancasInterna}
      isAdmin
    />
    {/* ------ */}
    <Route path="/cadastros/produtos" exact component={Produtos} isAdmin />
    <Route
      path="/cadastros/produtos/novo"
      exact
      component={NovoProduto}
      isAdmin
    />
    <Route
      path="/cadastros/produtos/produto/:id"
      exact
      component={ProdutosInterna}
      isAdmin
    />
    {/* ------ */}

    <Route component={Pag404} isPrivate />
  </Switch>
);

export default Routes;

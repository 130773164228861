import styled from 'styled-components';

export const ListHolder = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  div.container {
    padding: 15px;
  }
`;

ListHolder.defaultProps = {
  className: 'listHolder',
};

export const List = styled.ul`
  width: 100%;
  flex: 1 1;

  max-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  overflow-x: scroll !important;

  list-style: none;
  margin: 0;

  @media (max-width: 768px) {
    min-width: 800px;
  }
`;

List.defaultProps = {
  className: 'list',
};

export const Line = styled.li`
  width: 100%;
  min-height: 40px;
  padding: 5px;

  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #fff;

  border-bottom: 1px solid rgb(175 175 175 / 30%);

  transition: 0.3s all;

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.7;
  }

  &:nth-child(2n) {
    background: #fafafa;
  }

  &.indice {
    border-bottom: 1px solid rgb(175 175 175 / 100%);
  }

  @media (max-width: 768px) {
    /* grid-template-columns: 1fr !important; */
  }
`;

Line.defaultProps = {
  className: 'line',
};

export const LineItem = styled.div`
  margin: auto;
  padding: 5px;
  height: 100%;

  color: var(--text-color-2);

  flex: 1;
  min-width: 0;

  align-self: center;

  text-align: center;

  border-right: 1px solid rgb(175 175 175 / 30%);

  &:last-of-type {
    border-right: none;
  }

  &.indice {
    font-weight: 600;
  }

  &#status {
    overflow: visible !important;
  }

  .acao {
    margin: 0 4px;
    &:hover {
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
    }
  }

  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-transform: capitalize;

  *,
  &,
  button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;

    margin: auto 0;
  }

  @media (max-width: 768px) {
    margin: 5px 0;
    border-right: none;
  }
`;

LineItem.defaultProps = {
  className: 'lineItem',
};

import React from 'react';

import { Container } from './styles';

const Inicio: React.FC = () => {
  return (
    <Container>
      <h1>Seja bem vindo</h1>
      <h2>:)</h2>
    </Container>
  );
};

export default Inicio;

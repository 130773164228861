import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AxiosError, AxiosResponse } from 'axios';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import getValidationErrors from 'utils/getValidationErrors';

import Spinner from 'components/Spinner';
import { Container, BodyHolder } from '../interna-styles';
import { TitleHolder } from '../styles';

interface ParamsTypes {
  id: string | undefined;
}

const BancasInterna: React.FC = () => {
  const module = 'banca';
  const module_label = 'Banca';

  const { id } = useParams<ParamsTypes>();

  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const [update, setUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any | undefined>(undefined);
  const { addToast } = useToast();

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const inputs: IInput[] = [
    {
      name: 'cod_ponto',
      label: 'Código Ponto',
      tipo: 'input',
      type: 'number',
      required: true,
    },
    {
      name: 'nome',
      label: 'Nome',
      tipo: 'input',
      required: true,
    },
    {
      name: 'razao_social',
      label: 'Razão Social',
      tipo: 'input',
      required: true,
    },
    {
      name: 'tipo',
      label: 'Tipo',
      tipo: 'input',
      required: true,
    },
    {
      name: 'contato',
      label: 'Contato',
      tipo: 'input',
    },
    {
      name: 'endereco',
      label: 'Endereço',
      tipo: 'input',
      required: true,
    },
    {
      name: 'numero',
      label: 'Número',
      tipo: 'input',
      required: true,
    },
    {
      name: 'bairro',
      label: 'Bairro',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cidade',
      label: 'Cidade',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cep',
      label: 'CEP',
      tipo: 'input',
      required: true,
    },
    {
      name: 'telefone',
      label: 'Telefone',
      tipo: 'phone',
      required: true,
    },
    {
      name: 'cnpj',
      label: 'CNPJ',
      tipo: 'input',
      required: true,
    },
    {
      name: 'insc_estadual',
      label: 'Inscrição Estadual',
      tipo: 'input',
      required: true,
    },
    {
      name: 'email',
      label: 'E-mail',
      tipo: 'input',
      type: 'email',
    },
  ];

  const get = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/${module}s/${module}/${id}`)
      .then(async (res: AxiosResponse) => {
        setData(res.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: `Ocorreu um erro ao buscar o ${module.toLocaleLowerCase()}, tente novamente.`,
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, id, module, update]);

  useEffect(() => {
    get();
  }, [get]);

  const handleSubmit = useCallback(
    async (dat: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cod_ponto: Yup.number().required('Código do Ponto obrigatório'),
          nome: Yup.string().required('Nome obrigatório'),
          razao_social: Yup.string().required('Razão social obrigatória'),
          tipo: Yup.string().required('Tipo obrigatório'),
          endereco: Yup.string().required('Endereço obrigatório'),
          numero: Yup.string().required('Número obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          cep: Yup.string().required('CEP obrigatório'),
          telefone: Yup.string().required('Telefone obrigatório'),
          cnpj: Yup.string().required('CNPJ obrigatório'),
          insc_estadual: Yup.string().required(
            'Inscrição Estadual obrigatório'
          ),
        });

        await schema.validate(dat, {
          abortEarly: false,
        });

        api
          .put(`/${module}s/${module}/${id}`, dat)
          .then((res: AxiosResponse) => {
            const { id: idd } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                idd && ` de id: ${idd}`
              } atualizado com sucesso`,
            });
            history.push(`/cadastros/${module}s`);
            setUpdate(!update);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao atualizar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na atuaização do ${module}`,
          description: `Ocorreu um erro ao atualizar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, id, update]
  );

  return (
    <Container>
      {data ? (
        <>
          <BodyHolder>
            <TitleHolder>
              {module_label} #{id}:
            </TitleHolder>
            <FormConstructor
              color="quaternary"
              formColumns="1fr 1fr"
              formRef={formRef}
              inputs={inputs}
              saveBtn={saveBtn}
              submitFnc={handleSubmit}
              initialData={data}
            />
          </BodyHolder>
        </>
      ) : loading ? (
        <Spinner size={15} />
      ) : (
        `${
          module.lastIndexOf('s') === module.length - 1
            ? module.slice(0, -1)
            : module
        } não encontrado...`
      )}
    </Container>
  );
};

export default BancasInterna;

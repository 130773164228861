import React from 'react';
import { useHistory } from 'react-router';

import BoxContainer from 'components/BoxContainer';
import Listagem, { IRow } from 'components/Listagem';

import { Container, TitleHolder, ListHolder } from '../styles';

const Produtos: React.FC = () => {
  const history = useHistory();

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      data: 'cod_produto',
      label: 'Código Produto',
    },
    {
      data: 'des_produto',
      label: 'Descrição Produto',
    },
    {
      data: 'edicao',
      label: 'Edição',
    },
    {
      data: 'isbn',
      label: 'ISBN',
    },
    {
      data: 'valor_un',
      label: 'Valor Unitário',
    },
    {
      data: 'desconto',
      label: 'Desconto',
    },
  ];

  return (
    <Container>
      <TitleHolder>
        Produtos:
        <button
          type="button"
          onClick={() => history.push('/cadastros/produtos/novo')}
        >
          Novo produto
        </button>
      </TitleHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <Listagem
            rows={rows}
            module="produtos"
            template="100px 1fr 3fr 1fr 1fr 250px 100px"
            editUrl="/cadastros/produtos/produto"
            indexUrl="/cadastros/produtos/produto"
            deleteUrl="/produtos/produto"
          />
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default Produtos;
